import React from "react"
import imgPortadaGuide from "@components/pageGuide/guides/guide11/image/imgPortadaGuia11.png"
import img1 from "@components/pageGuide/guides/guide11/image/img1.png"
import slider1 from "@components/pageGuide/guides/guide11/image/silder1.png"
import slider2 from "@components/pageGuide/guides/guide11/image/silder2.png"
import slider3 from "@components/pageGuide/guides/guide11/image/silder3.png"

const title = (
  <>
    <h1 className="titles-underlined-ebook ebook-ventas">
    Guía completa para utilizar WhatsApp en campañas de telemarketing
    </h1>
  </>
)

const textDescription = (
  <div>
    <p className="text-h2-ebook guide__description">
      Utilizar WhatsApp en tus campañas de telemarketing te ayudará a
      personalizar tus mensajes y adaptarlos a las preferencias de cada cliente
      potencial. Esto conlleva a tener una mayor efectividad dentro de tu
      estrategia, además, de un alza en los niveles de satisfacción ya que
      podrás ofrecer mensajes personalizados.
    </p>
  </div>
)

const linkForm = (
  <span>
    Al registrarte, aceptas los términos y condiciones de nuestra{" "}
    <a
      style={{ color: "#2C5C82", textDecoration: "none" }}
      href="https://beexcc.com/politica-seguridad-privacidad.html"
    >
      Política de Privacidad.{" "}
    </a>
  </span>
)

const textOne = (
  <p>
    Utiliza WhatsApp en las campañas de telemarketing es una excelente manera de
    llegar a tus clientes potenciales, siempre y cuando utilices los mensajes
    correctos, respetes su privacidad y crees una estrategia inteligente.
    <br /> <br />
    Se estima que el uso de WhatsApp aumenta en un 42% la satisfacción del
    cliente y genera una reducción del 30% en los tiempos de respuesta. Por eso,
    en esta guía aprenderás a utiliza WhatsApp dentro de tus campañas de
    telemarketing.
  </p>
)

const data = {
  start: {
    support: "Guía | Telemarketing",
    title: title,
    description: textDescription,
    classtext: "guide__description-container",
    link: linkForm,
    href: "/ebook-8.pdf",
    brochure: "Guía-para-una-buena-experiencia-de-compra",
    img: imgPortadaGuide,
  },

  slider: {
    title: "¿Qué encontrarás?",
    underlined: "encontrarás?",
    checklist: [
      {
        key: 1,
        text: "Aplica una estrategia Opt-in",
      },
      {
        key: 2,
        text: "Crea mensajes atractivos y únicos",
      },
      {
        key: 3,
        text: "Desarrolla un flujo de comunicación por WhatsApp",
      },
      {
        key: 4,
        text: "Implementa una estrategia de recontacto",
      },
    ],
    listPictures: [
      {
        key: 1,
        img: slider1,
      },
      {
        key: 2,
        img: slider2,
      },
      {
        key: 3,
        img: slider3,
      },
    ],
  },

  description: {
    cards: [
      {
        text: textOne,
        photo: img1,
        class: true,
      },
    ],
  },
}

export default data
